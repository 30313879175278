import React from "react";
import styled from "@emotion/styled";
import Title from "../UI/title";
import Flex from "../UI/Flex";

const Container = styled(Flex)`
  position: relative;
  width: 100%;
  min-height: 100vh;

  .container {
    flex: 1 0 70%;
    justify-content: center;
    padding: 0 20px 15px;

    @media all and (max-height: 400px) && (max-width: 767px) {
      justify-content: flex-start;
    }
  }
`;

function NotFound(props) {
  return (
    <Container direction={"column"} jc={"flex-start"}>
      <Flex className="container" direction={"column"}>
        <Title className={"text-center"}>
          <span className={"ct"}>Not found 404</span>
        </Title>
      </Flex>
    </Container>
  );
}

export default NotFound;
